import _ from "lodash";
import { DeviceType } from "../api/types/GenericDevice";

export const formatDeviceName = (deviceType: string | DeviceType, doNotUseDefault?: boolean): string => {
  if (!deviceType) return "";

  if (deviceType === "AIRWITS") {
    return "Airwits";
  }

  if (deviceType === "AIRWITS_IAQ") {
    return "Airwits IAQ";
  }

  if (deviceType === "AIRWITS_CO2") {
    return "Airwits CO2";
  }

  if (deviceType === "AIRWITS_CO2_PLUS") {
    return "Airwits CO2 +";
  }

  if (deviceType === "AIRWITS_PM") {
    return "Airwits PM";
  }

  if (deviceType === "DETECTIFY") {
    return "Detectify";
  }

  if (deviceType === "HOTDROP") {
    return "HotDrop";
  }

  if (deviceType === "ENVIROQ") {
    return "EnviroQ";
  }

  if (deviceType === "THERMALQ") {
    return "ThermalQ";
  }

  if (deviceType === "ADEUNIS_PULSE_WATER") {
    return "Adeunis Pulse Water Meter";
  }

  if (deviceType === "ADEUNIS_PULSE") {
    return "Adeunis Pulse Meter";
  }

  if (deviceType === "FLEXSENSE") {
    return "FlexSense";
  }
  if (deviceType === "VOLTDROP") {
    return "VoltDrop";
  }
  if (deviceType === "VOLTDROP_PHASE") {
    return "VoltDrop";
  }
  if (deviceType === "ROOM_RADAR") {
    return "Room Radar";
  }

  if (doNotUseDefault) {
    return "";
  }

  return _.upperFirst(_.lowerCase(deviceType));
};
